<template>
    <div>
        <h1>Aktionen</h1>
        <v-data-table :headers="headers"
                      :items="aktionen"
                      class="elevation-1">
            <template v-slot:item="props">
              <tr>
                <td class="text-xs-left"><h2>{{ props.item.name }}</h2></td>
                <td class="text-xs-left">{{ props.item.teilnehmerzahl }}</td>
                <td class="text-xs-left">
                     <v-radio-group v-model="props.item.zugriff" class="mt-3" @change="saveAktion(props.item)">
                                    <v-radio key="0"
                                             label="Markierte Personen darf jeder lösen"
                                             :value="0"></v-radio>
                                    <v-radio key="1"
                                             label="Markierte/ gelöste Personen werden für andere Nutzer gesperrt"
                                             :value="1"></v-radio>
                                    <v-radio key="2"
                                             label="Liste für alle sperren (außer Admins)"
                                             :value="2"></v-radio>
                                </v-radio-group>
                </td>
                <td class="text-xs-left">
                      <v-btn-toggle v-model="props.item.status" mandatory @change="saveAktion(props.item)">
                                <v-btn :value="1" color="green">
                                    <v-icon color="white" class="white--text">mdi-tab</v-icon>
                                    Aktiviert
                                </v-btn>
                                <v-btn :value="0" color="red" >
                                    <v-icon color="white">mdi-power-settings</v-icon>
                                    Deaktiviert
                                </v-btn>
                            </v-btn-toggle>

                </td>
              </tr>

            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    export default {
        data() {
            return {
                headers: [{ text: 'Name', value: 'name' },
                { text: 'Teilnehmerzahl', value: 0 },
                    { text: 'Sichtbarkeit', value: 'sicht' },
                    { text: 'Status', value: 'status' },],
                aktionen:[]


            }
        },
        computed: {
        },
        mounted() {
        },
        created() {
            //this.$store.dispatch('getAktionen');
            this.getAllAktionen();
        },
        methods: {
            saveAktion: function (aktion) {
                this.$http.post('api/Stammdaten/AlterAktion', aktion).then(response => {
                }).catch((error) => console.log(error))
            },
             getAllAktionen: function (aktion) {
                 this.$http.get('api/Stammdaten/AlleAktionen').then(response => {
                     this.aktionen = response.data;
                }).catch((error) => console.log(error))
            },
        }
    }
</script>

<style>
</style>
